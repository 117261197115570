import styles from '@styles/components/UpdateCompany/UpdateCompany.module.css';
import { emailSupport, hotline } from '@utils/configs/Common/CommonConfigs';
import React from 'react';

export default function RightBanner() {
  return (
    <div className={styles.rightBanner}>
      <div className="flex-1 flex flex-col p-24px z-10">
        <p className="text-24 font-bold font-textBold text-blue-main uppercase">
          hoàn thành hồ sơ để đăng dự án
        </p>
        <p className="text-16 font-text text-blue-main mt-16px">
          Bạn hãy hoàn thành hồ sơ cá nhân để hưởng được 1 triệu tiền thưởng và
          được sử dụng tất cả các dịch vụ ưu đãi miễn phí của chúng tôi
        </p>
        <p
          className="text-16 font-bold text-blue-main"
          style={{ marginTop: 48 }}>
          Gặp vấn đề?
        </p>
        <p className="text-13 text-blue-main mt-16px">Số điện thoại hỗ trợ:</p>
        <p className="text-13 font-bold text-blue-main">{hotline}</p>
        <p className="flex flex-row items-center mt-16px space-x-1">
          <p className="text-13 text-blue-main">Email hỗ trợ:</p>
          <p className="text-13 text-blue-main font-bold">{emailSupport}</p>
        </p>
      </div>
      <img
        alt="bitmap"
        src="/images/bitmap.webp"
        className="absolute bottom-2"
      />
    </div>
  );
}
