import gql from 'graphql-tag';

export const CHANGE_STATUS_USER_PROFILE = gql`
  mutation changeStatusUserProfile(
    $userId: ObjectID!
    $status: String!
    $rejectedReason: String
  ) @api(name: users) {
    changeStatusUserProfile(
      userId: $userId
      status: $status
      rejectedReason: $rejectedReason
    ) {
      msg
    }
  }
`;

export const UPDATE_COMPANY_PROFILE = gql`
  mutation updateCompanyProfile(
    $companyName: String!
    $establishedDate: Date!
    $taxId: String!
    $companyLicense: [ObjectID]!
    $attachments: [ObjectID]!
    $mediaOfCompany: [ObjectID]!
    $description: String!
    $email: String!
    $avatar: ObjectID!
    $province: ObjectID!
  ) @api(name: users) {
    updateCompanyProfile(
      companyName: $companyName
      establishedDate: $establishedDate
      taxId: $taxId
      companyLicense: $companyLicense
      attachments: $attachments
      mediaOfCompany: $mediaOfCompany
      description: $description
      email: $email
      avatar: $avatar
      province: $province
    ) {
      msg
      success
    }
  }
`;

export const SEND_DELETE_ACCOUNT_REQUEST = gql`
  mutation requestDeleteAccount @api(name: users) {
    requestDeleteAccount {
      msg
      success
    }
  }
`;
