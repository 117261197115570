import { UpdateCompanyContext } from '@components/modals/UpgradeCompany/context';
import styles from '@styles/components/UpdateCompany/UpdateCompany.module.css';
import ModalWrapper from '@components/ModalWrapper';
import RightBanner from '@components/modals/UpgradeCompany/components/RightBanner';
import EnterInfo from '@components/modals/UpgradeCompany/components/EnterInfo';
import React, { useState } from 'react';
import UpdateSuccess from '@components/modals/UpgradeCompany/components/UpdateSuccess';

type Props = {
  openModal?: string,
  setOpenModal?: Function,
};

export default function UpgradeCompanyModal(props: Props) {
  const { openModal, setOpenModal } = props,
    [openModalSuccess, setOpenModalSuccess] = useState(false);
  return (
    <UpdateCompanyContext.Provider
      value={{
        openModal,
        setOpenModal,
        openModalSuccess,
        setOpenModalSuccess,
      }}>
      <ModalWrapper
        open={openModal}
        className="rounded-rd8px"
        onClose={() => setOpenModal(false)}>
        <div
          className={styles.containerModal}
          style={{ width: 822, height: 703 }}>
          <RightBanner />
          <EnterInfo />
        </div>
      </ModalWrapper>
      {openModalSuccess && (
        <ModalWrapper
          className="rounded-rd8px max-w-95%"
          open={openModalSuccess}
          onClose={() => setOpenModalSuccess(false)}>
          <UpdateSuccess close={() => setOpenModalSuccess(false)} />
        </ModalWrapper>
      )}
    </UpdateCompanyContext.Provider>
  );
}
