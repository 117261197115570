import styles from '@styles/components/UpdateCompany/UpdateCompany.module.css';
import MyButton from '@components/Button';
type Props = {
  close?: Function,
};
export default function UpdateSuccess({ close }: Props) {
  return (
    <div className={styles.successModal}>
      <img
        src="/svg/successful.svg"
        alt="icon"
        className="w-80px h-80px self-center"
      />
      <p
        className="text-24 font-bold font-textBold mt-16px mb-12px text-center"
        style={{ color: '#40d124' }}>
        Chúc mừng bạn đã hoàn thành hồ sơ doanh nghiệp
      </p>
      <p className="text-14 font-text text-blue-main text-center">
        Chúng tôi sẽ gởi thông báo cho bạn khi duyệt thành công. Cám ơn bạn đã
        tin dùng RealTech247
      </p>
      <MyButton label="Đóng" wrapperClass="mt-16px" onPress={close} />
    </div>
  );
}
